<template>
  <v-card
    class="mt-0 mb-0 pt-0"
    elevation="1"
    height="165"
    :style="$vuetify.breakpoint.smAndDown ? 'border-radius: 8px; padding: 5px; display: flex; align-items: center;' : 'border-radius: 8px; padding: 10px; display: flex; align-items: center;'"
  >
    <v-row style="height: 150px; width: 100%;">
      <v-col
        cols="2"
        md="2"
      >
        <div style="height: 100%; display: flex; flex-direction: column">
          <v-img
            contain
            max-height="130"
            max-width="500"
            :src="formatImageLink(produto.imagens[0])"
            @click="console.log('so pra manter na mesma linha')"
          />
        </div>
      </v-col>
      <v-col
        cols="10"
        md="10"
      >
        <v-row>
          <v-col
            cols="11"
            class="pt-0 pb-0"
          >
            <div>
              <div
                :title="produto.nome"
                :style="$vuetify.breakpoint.smAndDown ? 'font-size: 14px; color: #183B94;font-weight: 600; width: 100%; min-width: 30px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;' : 'font-size: 18px; color: #183B94; font-weight: 600; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'"
                @click="handleProdLink(produto.id)"
              >
                {{ produto.nome }}
              </div>
              <div
                :style="$vuetify.breakpoint.smAndDown ? 'font-size: 12px; font-weight: 600;' : 'font-size: 14px; font-weight: 600;'"
                @click="handleProdLink(produto.id)"
              >
                {{ produto.marca }}
              </div>
            </div>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.smAndUp"
            cols="12"
            class="pt-0 pb-0"
          >
            <div
              v-if="(this.$store.state.user.roles[0].slug === 'produtor' && produto.disponibilidade === 2 ) || produto.preco <= 0"
              :style="$vuetify.breakpoint.smAndUp ? 'font-size: 14px; font-weight: 600; color: #ff8f00; height: 25px;' : 'font-size: 10px; font-weight: 600; color: #ff8f00; height: 22px;'"
            >
              O Clube retornará uma cotação para a quantidade!
            </div>
            <div
              v-else
              style="height: 25px;"
            />
          </v-col>
          <v-col
            v-else
            cols="7"
            class="pt-0 pb-0"
          >
            <div
              v-if="(this.$store.state.user.roles[0].slug === 'produtor' && produto.disponibilidade === 2 ) || produto.preco <= 0"
              :style="$vuetify.breakpoint.smAndUp ? 'font-size: 14px; font-weight: 600; color: #ff8f00; height: 25px;' : 'font-size: 10px; font-weight: 600; color: #ff8f00; height: 22px;'"
            >
              O Clube retornará uma cotação para a quantidade!
            </div>
            <div
              v-else
              style="height: 25px;"
            />
          </v-col>
        </v-row>
        <div
          v-if="$vuetify.breakpoint.smAndUp"
          style="display: grid; grid-template-columns: 1fr 1.3fr 1fr 1fr 1fr 1fr; margin-top: 8px; gap: 8px; align-items: end;"
        >
          <div
            style="font-size: 12px; font-weight: 600;"
            class="first-grid"
          >
            <div>
              Quantidade
            </div>
            <div style="font-size: 12px;">
              {{ produto.quantidade }} unidades(s)
            </div>
          </div>
          <div class="second-grid">
            <div
              style="width: 90px; height: 85px;"
            />
          </div>
          <div
            v-if="(this.$store.state.user.roles[0].slug === 'tecnico' && produto.preco > 0) || (produto.disponibilidade !== 2)"
            class="third-grid"
          >
            <div style="font-size: 12px; font-weight: 600;">
              Valor Unitário
            </div>
            <div
              style="font-size: 12px;"
            >
              {{ produto.preco > 0 ? 'R$ ' + currencyFormatter( produto.preco ) : '- - - - - - - - - -' }}
            </div>
          </div>
          <div
            v-if="(this.$store.state.user.roles[0].slug === 'tecnico' && produto.preco > 0) || (produto.disponibilidade !== 2)"
            class="fourth-grid"
          >
            <div
              style="font-size: 12px; font-weight: 600;"
            >
              Subtotal
            </div>
            <div
              style="font-size: 12px;"
            >
              {{ produto.subtotal > 0 ? 'R$ ' + currencyFormatter( produto.subtotal ) : '- - - - - - - -' }}
            </div>
          </div>
          <div
            v-if="(this.$store.state.user.roles[0].slug === 'tecnico' && produto.preco > 0) || (produto.disponibilidade !== 2)"
            class="fifth-grid"
          >
            <div
              style="font-size: 12px; font-weight: 600;"
            >
              Desconto
            </div>
            <div
              style="font-size: 12px;"
            >
              {{ produto.vlrdesconto > 0 ? 'R$ ' + currencyFormatter( produto.vlrdesconto ) : 'R$ 0,00' }}
            </div>
          </div>
          <div
            v-if="(this.$store.state.user.roles[0].slug === 'tecnico' && produto.preco > 0) || (produto.disponibilidade !== 2)"
            class="sixth-grid"
          >
            <div
              style="font-size: 12px; color: #143693; font-weight: 600; font-family: Helvetica, Arial, sans-serif;"
            >
              Valor Total
            </div>
            <div
              style="font-size: 12px; font-weight: 600;"
            >
              {{ produto.vlrtotal > 0 ? 'R$ ' + currencyFormatter( produto.vlrtotal ) : '- - - - - - - -' }}
            </div>
          </div>
        </div>
        <div
          v-else
          style="display: grid; grid-template-columns: 2fr 1fr; margin-top: 8px; gap: 8px; align-items: end;"
        >
          <div
            class="first-grid"
            style="font-size: 12px; font-weight: 600; height: 90px; display: flex; flex-direction: column; justify-content: flex-end"
          >
            <div>
              Quantidade
            </div>
            <div style="font-size: 12px;">
              {{ produto.quantidade }} unidades(s)
            </div>
          </div>
          <div
            class="second-grid"
            style="display: flex; flex-direction: column; align-items: end"
          >
            <div v-if="produto.preco > 0">
              <div style="font-size: 10px; font-weight: 600; margin-right: 4px;">
                Valor Unitário
              </div>
              <div
                style="font-size: 10px; margin-right: 4px; text-align: end"
              >
                {{ produto.preco > 0 ? 'R$ ' + currencyFormatter( produto.preco ) : '- - - - - - - - - -' }}
              </div>
            </div>
            <div v-if="produto.vlrdesconto > 0">
              <div
                style="font-size: 10px; font-weight: 600; margin-right: 4px;"
              >
                Desconto
              </div>
              <div
                style="font-size: 10px;margin-right: 4px;"
              >
                {{ produto.vlrdesconto > 0 ? 'R$ ' + currencyFormatter( produto.vlrdesconto ) : '- - - - - - - -' }}
              </div>
            </div>
            <div v-if="produto.preco > 0">
              <div
                style="font-size: 10px; color: #143693; font-weight: 600; margin-right: 4px;"
              >
                Valor Total
              </div>
              <div
                style="font-size: 10px; font-weight: 600; margin-right: 4px;"
              >
                {{ produto.vlrtotal > 0 ? 'R$ ' + currencyFormatter( produto.vlrtotal ) : '- - - - - - - -' }}
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import { formatDescText, handleProdLink, formatImageLink } from './helpers'
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    props: {
      produto: {
        type: Object,
        default: () => (
          {
            id: 'fa6d6298-60bf-4509-9dbb-79d1c51a3d90',
            nome: 'Excede (CCFA)',
            marca: 'Zoetis',
            descricao: 'EXCEDE (CCFA) Suspensão Estéril – 200 mg/mL é uma formulação pronta para uso que contém ácido livre cristalino de ceftiofur, um antibiótico com largo espectro de ação, pertencente ao grupo das cefalosporinas, ativo contra bactérias Gram positivas e Gram negativas, incluindo cepas produtoras de β-lactamase. Como outras cefalosporinas, ceftiofur é bactericida in vitro, devido a sua ação de inibição da síntese da parede bacteriana.',
            imagem: 'http://clubedoprodutor.com.br/produtos/24b6b33a-3d1f-409e-939a-ab81bed0929f.0.png',
          }
        ),
      },
    },
    data () {
      return {
        descLength: 150,
        currencyFormatter,
      }
    },
    methods: {
      formatDescText,
      handleProdLink,
      formatImageLink,
    },
  }
</script>
