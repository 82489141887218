<template>
  <base-loading v-if="loading" />
  <div
    v-else
    style="padding: 10px;"
  >
    <div
      class="font-weight-bold"
      style="font-size: 28px; color: #183B94; margin-bottom: 10px"
    >
      Finalização pedido
    </div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="8"
        xl="8"
        class="py-0"
      >
        <v-card
          v-if="$vuetify.breakpoint.smAndUp"
          class="my-3 py-2"
          elevation="1"
          style="border-radius: 8px;"
        >
          <v-card-title>
            <h2
              style="font-size: 18px; font-weight: 700;"
            >
              Resumo do itens do pedido
            </h2>
          </v-card-title>
          <div
            style="min-height: 1200px; padding: 16px"
          >
            <card-finalizacao
              v-for="item in produtos"
              :key="item.id"
              class="mb-5"
              :produto="item"
            />
          </div>
        </v-card>
        <div v-else>
          <div
            style="min-height: 100px;"
          >
            <card-finalizacao
              v-for="item in produtos"
              :key="item.id"
              class="mb-5"
              :produto="item"
            />
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="4"
        xl="4"
      >
        <v-card
          class="mt-0 pb-2"
          elevation="1"
          style="border-radius: 8px;"
        >
          <v-card-title class="d-flex justify-space-between pb-0">
            <h3 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Pedido
            </h3>
            <h3 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              #{{ pedido_numero }}
            </h3>
          </v-card-title>
          <v-card-title class="d-flex justify-space-between pb-0 pt-0">
            <h4 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Data e hora:
            </h4>
            <h4 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              {{ moment(data_atual).format('DD/MM/YYYY - HH:mm:ss') }}
            </h4>
          </v-card-title>
          <v-divider class="ml-4 mr-4 mt-4" />
          <v-card-title class="d-flex justify-space-between pb-0 pt-0">
            <h4 style="font-size: 14px; font-weight: 700;">
              Dados do produtor
            </h4>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Produtor:</h4>
            <h4> {{ selecaoFazenda.produtores[0].nome }}</h4>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Fazenda:</h4>
            <h4>{{ selecaoFazenda.razao_social }}</h4>
          </v-card-title>
        </v-card>
        <v-card
          class="mt-0"
          elevation="1"
          style="border-radius: 8px;"
        >
          <v-card-title>
            <h2 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Detalhes do pagamento
            </h2>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Subtotal dos produtos</h4>
            <h4>R$ {{ currencyFormatter( precoSubTotal ) }}</h4>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Descontos</h4>
            <h4> R$ {{ currencyFormatter(descontoTotal) }}</h4>
          </v-card-title>
          <v-row>
            <v-col
              style="font-size: 16px; font-weight: 700;"
              class="d-flex justify-space-between pb-0 pt-0 mt-8 pl-7"
            >
              <h4>Total</h4>
            </v-col>
            <v-col class="pr-7 pt-8">
              <h4 style="text-align: right;">
                R$ {{ currencyFormatter( precoTotal ) }}
              </h4>
              <p
                class="mb-0"
                style="text-align: right; font-size: 11px; font-weight: 300; color: #808080;font-family: Helvetica, Arial, sans-serif;"
              >
                Em até {{ parcelamento[parcelamento.length-1].parcela }} parcela(s).
              </p>
            </v-col>
          </v-row>
          <v-divider class="ml-4 mr-4 mt-4" />
          <v-card-title>
            <h2 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Forma de pagamento
            </h2>
          </v-card-title>
          <v-autocomplete
            v-model="parcelar"
            :items="parcelamento"
            filled
            item-text="valor"
            item-value="parcela"
            return-object
            label="Selecione o Parcelamento"
            clearable
            outline
            hide-details
            autocomplete="off"
            class="rounded-lg caption font-weight-bold"
            style="font-size: 14px !important;font-family: Helvetica, Arial, sans-serif; padding: 10px;"
          />
          <v-card-title>
            <h2 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Data ideal para entrega
            </h2>
          </v-card-title>
          <v-card-text>
            <data-field
              v-model="data_i"
              :label="'Data ideal para entrega'"
            />
          </v-card-text>
        </v-card>
        <v-card
          class="mt-0"
          style="border-radius: 8px;"
        >
          <v-card-title>
            <h2 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Observação
            </h2>
          </v-card-title>
          <v-textarea
            v-model="observacao"
            filled
            style="padding: 10px;"
            outlined
            no-resize
            label="Digite uma observação"
            validate-on-blur
            hide-details
            height="100"
          />
        </v-card>
        <v-card
          v-if="this.$store.state.user.roles[0].slug === 'tecnico'"
          class="mt-0"
          style="border-radius: 8px;"
        >
          <v-card-title>
            <h2 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Tipo de atendimento
            </h2>
          </v-card-title>
          <v-card-text class="pb-4">
            <v-radio-group v-model="radioGroup">
              <v-radio
                color="#183B94"
                label="Telefone"
                value="1"
              />
              <v-radio
                color="#183B94"
                label="Online"
                value="2"
              />
            </v-radio-group>
          </v-card-text>
        </v-card>
        <v-card v-if="radioGroup === '1'">
          <v-card-title>
            <h2 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Informação do contato
            </h2>
          </v-card-title>
          <v-card-text>
            <div style="margin-top: 5px; font-size: 14px; color: #183B94; font-weight: 700;">
              Data
              <data-field
                v-model="data_i"
                :label="'Data'"
                hide-details
              />
            </div>
            <div style="margin-top: 5px; font-size: 14px; color: #183B94; font-weight: 700;">
              Observação
              <v-textarea
                v-model="observacao_atendimento"
                filled
                outlined
                no-resize
                label="Digite uma observação"
                validate-on-blur
                hide-details
                height="80"
              />
            </div>
          </v-card-text>
        </v-card>
        <v-card-actions :class="$vuetify.breakpoint.smAndDown ? 'pt-0 pl-0 pr-0 mb-12' : 'pt-0 pl-0 pr-0'">
          <v-row dense>
            <v-col
              cols="12"
            >
              <v-btn
                color="success"
                dark
                block
                small
                height="35px"
                class="text-none text-white"
                style="border-radius: 8px;"
                @click="handleEnviaPedido()"
              >
                Solicitar Aprovação do Pedido
                <v-icon
                  small
                  color="white"
                  class="ml-1"
                >
                  mdi-clipboard-check-outline
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
            >
              <v-btn
                color="primary"
                small
                height="35px"
                block
                class="text-none mx-0 text-white"
                style="border-radius: 8px;"
                @click="handleBackButton()"
              >
                Retornar ao Carrinho
                <v-icon
                  small
                  color="white"
                  class="ml-1"
                >
                  mdi-backup-restore
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import CarrinhoStore, { BOOTSTRAP, BOOTSTRAP_PEDIDO, FINALIZAR_PEDIDO } from '@/store/modules/carrinho'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import CardFinalizacao from './_cardFinalizacao.vue'
  import { currencyFormatter } from '../../utils/formatter'
  import moment from 'moment'

  export default {
    components: {
      CardFinalizacao,
    },
    data () {
      return {
        model: null,
        currencyFormatter,
        parcelar: 1,
        data_i: 'from',
        moment,
        radioGroup: 1,
      }
    },
    computed: {
      ...mapState('loja', ['loading']),
      ...mapState('carrinho', ['produtos', 'pedido_numero', 'data_atual', 'data_entrega']),
      ...mapState('fazendas', ['selecaoFazenda']),
      ...mapState('produtores', ['selecaoProdutor']),
      ...mapGetters('carrinho', ['precoTotal', 'descontoTotal', 'precoSubTotal']),
      ...mapFields('carrinho', ['parcelas', 'observacao', 'observacao_atendimento', 'data_entrega']),
      parcelamento () {
        const parcelamento = []
        const itens = this.produtos
        let parcelaMinima = 0

        itens.sort(function (a, b) {
          return parseFloat(b.vlr_parcelas) - parseFloat(a.vlr_parcelas)
        })

        if (parseFloat(itens[0].vlr_parcelas) !== 0) {
          parcelaMinima = itens[0].vlr_parcelas
        }

        itens.sort(function (a, b) {
          return parseInt(b.parcelamento) - parseInt(a.parcelamento)
        })

        for (let i = 0; i < (itens[0].parcelamento !== '' ? itens[0].parcelamento : 1); i++) {
          if (parcelaMinima === 0 || (this.precoTotal / ((i + 1) > 0 ? (i + 1) : 1)) >= parcelaMinima) {
            parcelamento.push({ parcela: i + 1, valor: (i + 1) + ' x R$ ' + currencyFormatter(this.precoTotal / (i + 1)) })
          };
        }

        if (parcelamento.length === 0) {
          parcelamento.push({ parcela: 1, valor: 1 + ' x R$ ' + currencyFormatter(this.precoTotal) })
        }

        return parcelamento
      },
    },
    async created () {
      if (!this.$store.hasModule('carrinho')) { this.$store.registerModule('carrinho', CarrinhoStore) }
      if (this.$store.getters['loja/catalogoFiltered'].length === 0) {
        await this.$store.dispatch('loja/BOOTSTRAP')
      }

      this.BOOTSTRAP_PEDIDO()
      this.data_i = this.data_entrega
    },
    methods: {
      ...mapActions('carrinho', [BOOTSTRAP, BOOTSTRAP_PEDIDO, FINALIZAR_PEDIDO]),
      handleBackButton () {
        this.$router.go(-1)
      },
      handleEnviaPedido () {
        this.parcelas = (this.parcelar.parcela === undefined ? this.parcelar : this.parcelar.parcela)
        this.FINALIZAR_PEDIDO()
      },
    },
  }
</script>
